// import logo from './logo.svg';
import './App.css';

import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Popper from '@mui/material/Popper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { TwitterPicker } from 'react-color'

const REACT_APP_API_BASE_URL=process.env.REACT_APP_API_BASE_URL || "https://api.lopoly.me/v1";

const AUTHENTICATE_URL = `${REACT_APP_API_BASE_URL}/oauth/twitter/1/authenticate`;

const NOT_AUTHENTICATED = "not authenticated";

const TOKEN_COOKIE_NAME = "token";

// https://bareynol.github.io/mui-theme-creator/
const theme = createTheme({
  palette: {
    type: 'dark',
    mode: 'dark',
    primary: {
      main: '#fe1ef7',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#17192c',
      paper: '#17192c',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

/**
 * Converts the given blob into a Promise for a data url
 */
function dataUrlPromise(blob) {
  return new Promise((resolve, reject) => {
    const r = new FileReader();
    r.onload = function () {
      resolve(r.result);
    };
    r.onerror = reject;
    r.readAsDataURL(blob);
  });
}

/**
 * Loads a face from the social avatar of the token's owner 
 */
function detectFacesPromise(token) {
  console.log("detecting");
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/images/faces/detect`, {
    "method": "POST",
    "headers": {
      "authorization": "bearer " + token,
      "content-type": "application/json",
    },
    "body": JSON.stringify({
      "image": { "type": "profilePicture" }
    })
  }).then(response => {
    if (response.status === 401)
      throw new Error(NOT_AUTHENTICATED);
    if (response.status !== 200)
      throw new Error("Failed to extract face: " + response.status);
    return response.json();
  });
}

/**
 * Loads a face from a social avatar and renders it as a transparent PNG. Returns a blob of the image.
 */
function renderAvatarPromise(token, face, orientation = "neutral", backgroundColor = null) {
  console.log("rendering");
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/avatars/render`, {
    "method": "POST",
    "headers": {
      "authorization": "bearer " + token,
      "content-type": "application/json",
      "accept": "image/png"
    },
    "body": JSON.stringify({
      "face": face.id,
      "orientation": orientation,
      "background": backgroundColor
        ? { "type": "color", "color": backgroundColor }
        : { "type": "transparent" }
    })
  }).then(response => {
    if (response.status === 401)
      throw new Error(NOT_AUTHENTICATED);
    if (response.status !== 200)
      throw new Error("Failed to extract face: " + response.status);
    return response.blob();
  });
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function deleteCookie(name) {
  document.cookie = name+"=; Max-Age=-99999999;";
}

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function invertColor(c) {
  function f(n) {
    return n.toString(16).padStart(2, '0');
  }
  var r = parseInt(c.slice(1, 3), 16);
  var g = parseInt(c.slice(3, 5), 16);
  var b = parseInt(c.slice(5, 7), 16);
  return `#${f(255 - r)}${f(255 - g)}${f(255 - b)}`;
}

function ColorPicker(props) {
  const { color, disabled, onChange } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(e) {
    if (open) {
      setOpen(false);
      setAnchorEl(null);
    }
    else {
      setAnchorEl(e.currentTarget);
      setOpen(true);
    }
  }

  return (
    <div className="colorPicker">
      <button
        type="button"
        disabled={disabled}
        onClick={handleClick}
        style={{ "backgroundColor": color, "width": "100%", "color": invertColor(color) }}
      >
        {color}
      </button>
      <Popper placement="bottom-start" open={open} anchorEl={anchorEl}>
        <TwitterPicker
          color={color}
          width={226}
          onChange={(c) => {
            onChange(c);
            setOpen(false);
          }}
        />
      </Popper>
    </div >
  );
}

function FaceBoundingBox(props) {
  const { index, identifier, box, selected, onClick } = props;

  let classNames = "face";
  if (selected)
    classNames = classNames + " selected";

  return (
    <div
      className={classNames}
      identifier={identifier}
      index={index}
      style={{
        "position": "absolute",
        "left": box.left / 2,
        "top": box.top / 2,
        "width": (box.right - box.left) / 2,
        "height": (box.bottom - box.top) / 2
      }}
      onClick={onClick}
    />
  );
}

function SocialAvatar(props) {
  const { token, defaultAvatar, onLoaded, onFaceSelected } = props;

  const [imageLoaded, setImageLoaded] = useState(false);
  const [faces, setFaces] = useState(null);
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState(null);

  let classNames = "avatarContainer social";
  if (imageLoaded === false)
    classNames = classNames + " loading";

  useEffect(() => {
    if (imageLoaded && !error) {
      detectFacesPromise(token)
        .then(detectedFaces => {
          setFaces(detectedFaces.faces);
          if (detectedFaces.faces.length !== 0) {
            setSelected(0);
            onFaceSelected(detectedFaces.faces[0]);
          }
          else {
            console.log("no faces");
          }
          onLoaded(detectedFaces.faces.length);
        })
        .catch(e => {
          console.log("There was a problem detecting faces");
          console.log(e);
          if (e.message === NOT_AUTHENTICATED)
            document.location = AUTHENTICATE_URL;
          onLoaded(0);
        })
        .finally(() => {
          console.log("Finished detecting faces");
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, imageLoaded, error]);

  return (
    <div className={classNames}>
      <img
        alt="social avatar"
        key="image"
        style={{
          "width": 200,
          "height": 200
        }}
        onError={() => { setImageLoaded(true); setError(true); }}
        className="avatar social"
        src={error
          ? defaultAvatar
          : `${process.env.REACT_APP_API_BASE_URL}/v1/me/avatar?token=${encodeURIComponent(token)}`}
        onLoad={() => setImageLoaded(true)}
      />
      {
        faces
          ? faces.map((f, i) =>
            <FaceBoundingBox
              key={f.id}
              index={i}
              identifier={f.id}
              box={f.boundingBox}
              selected={i === selected}
              onClick={() => {
                setSelected(i);
                onFaceSelected(faces[i]);
              }}
            />)
          : []
      }
    </div>
  );
}

function LoPolyAvatar(props) {
  const { token, spinner, onComplete, face, orientation, backgroundColor } = props;

  const [dataUrl, setDataUrl] = useState(null);

  useEffect(() => {
    if (face) {
      onComplete(false);
      renderAvatarPromise(token, face, orientation)
        .then(imageBlob => {
          return dataUrlPromise(imageBlob);
        }).then(url => {
          setDataUrl(url);
          onComplete(true);
        }).catch(e => {
          console.log("Failed to render face");
          console.log(e);
          if (e.message === NOT_AUTHENTICATED)
            document.location = AUTHENTICATE_URL;
          onComplete(false);
        });
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [token, face, orientation]);

  return (
    <div className="avatarContainer lopoly" style={{ "backgroundColor": backgroundColor }}>
      <img
        alt="lopoly avatar"
        style={{ "width": 200, "height": 200 }}
        className="avatar lopoly"
        src={dataUrl || spinner} />
    </div>
  );
}

const NO_FACES = "NO_FACES";

const ERROR_MESSAGES = {
  NO_FACES: "There are no faces in your profile picture.",
  INTERNAL_ERROR: "Oops! There was a problem. Try again?"
};

function AuthenticatedPane(props) {
  const { token } = props;

  const [backgroundColor, setBackgroundColor] = useState("#17192c");
  const [faceCount, setFaceCount] = useState(-1);
  const [face, setFace] = useState(null);
  const [orientation, setOrientation] = useState("original");
  const [loaded, setLoaded] = useState(false);

  function download() {
    renderAvatarPromise(token, face, orientation, backgroundColor)
      .then(imageBlob => {
        return dataUrlPromise(imageBlob);
      }).then(dataUrl => {
        const link = document.createElement("a");
        link.download = "avatar.png";
        link.href = dataUrl;
        link.click();
      }).catch(e => {
        console.log("There was a problem downloading your avatar");
        console.log(e);
        if (e.message === NOT_AUTHENTICATED)
          document.location = AUTHENTICATE_URL;
      }).finally(() => {
        console.log("Finished rendering avatar");
      });
    return false;
  }

  function reload() {
    deleteCookie(TOKEN_COOKIE_NAME);
    window.location.reload();
    return false;
  }

  const ready = loaded && faceCount > 0;
  const error = faceCount === 0 ? NO_FACES : null;

  return (
    <div className="authenticatedPanel">
      <Container maxWidth="sm" fixed style={{ "textAlign": "left" }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="baseline"
        >
          <Grid item sm={6} xs={12}>
            <SocialAvatar
              token={token}
              defaultAvatar="/default-avatar.png"
              onLoaded={(faceCount) => setFaceCount(faceCount)}
              onFaceSelected={(f) => setFace(f)}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <LoPolyAvatar
              token={token}
              face={face}
              spinner="/outline.png"
              onComplete={success => setLoaded(true)}
              backgroundColor={backgroundColor}
              orientation={orientation}
            />
          </Grid>

          {error
            ? (
              <Grid item xs={12}>
                <Alert severity="error">{ERROR_MESSAGES[error]}</Alert>
              </Grid>
            )
            : <></>}

          <Grid style={{ "textAlign": "left" }} item xs={6}>
            🎨 Background Color
          </Grid>
          <Grid style={{ "textAlign": "left" }} item xs={6}>
            <ColorPicker
              disabled={!ready}
              color={backgroundColor}
              onChange={color => setBackgroundColor(color.hex)}
            />
          </Grid>
          <Grid style={{ "textAlign": "left" }} item xs={6}>
            🧭 Look In Direction
          </Grid>
          <Grid style={{ "textAlign": "left" }} item xs={6}>
            <FormControl fullWidth variant="standard" disabled={!ready}>
              <Select
                value={orientation}
                id="orientation-select"
                onChange={e => setOrientation(e.target.value)}
                label="Orientation"
              >
                <MenuItem value="original">Match Social Avatar</MenuItem>
                <MenuItem value="neutral">Straight Ahead</MenuItem>
                <MenuItem value="north">Straight Up</MenuItem>
                <MenuItem value="northeast">Up and Right</MenuItem>
                <MenuItem value="east">Straight Right</MenuItem>
                <MenuItem value="southeast">Down and Right</MenuItem>
                <MenuItem value="south">Straight Down</MenuItem>
                <MenuItem value="southwest">Down and Left</MenuItem>
                <MenuItem value="west">Straight Left</MenuItem>
                <MenuItem value="northwest">Up and Left</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              disabled={!ready}
              style={{ "width": "100%" }}
              variant="contained"
              onClick={download}
              color="primary"
            >
              Download Your Avatar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={!ready}
              style={{ "width": "100%", "border": "1px solid white" }}
              variant="contained"
              onClick={reload}
              color="background"
            >
              Reload Your Avatar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

function AnonymousPane(props) {
  return (
    <div className="anonymousPane">
      <Button href={AUTHENTICATE_URL} variant="contained">Authenticate with Twitter</Button>
    </div>
  )
}

function App() {
  let token = null;
  if (!token) {
    token = getParameterByName(TOKEN_COOKIE_NAME);
    if (token) {
      setCookie(TOKEN_COOKIE_NAME, token, 1);
      document.location = document.location.href.split('?')[0];
    }
  }
  if (!token) {
    token = getCookie(TOKEN_COOKIE_NAME);
  }
  if (token) {
    // Have we expired?
    let jwt = parseJwt(token);
    if (jwt.exp && jwt.exp > Date.now() / 1000) {
      // Everything is fine!
    }
    else {
      // We have a token and have expired. Nuke the token.
      token = null;
    }
  }
  else {
    // We're not authenticated. That's fine. We'll get the anonymous pane below.
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navigation w-nav">
          <div className="nav-container">
            <a href="/" aria-current="page" className="logo-block w-nav-brand w--current" aria-label="home">
              <img src="https://uploads-ssl.webflow.com/6268387bb71483996a8111c9/62685b53555f59fbc5832f99_LoPolyLogo.svg" loading="lazy" alt="" className="logo" />
            </a>
            <nav role="navigation" className="nav-menu w-nav-menu">
              <a href="https://www.lopoly.me/#about" target="_blank" rel="noreferrer" className="nav-link w-nav-link">About</a>
              <a href="https://www.lopoly.me/#roadmap" target="_blank" rel="noreferrer" className="nav-link w-nav-link">Roadmap</a>
              <a href="https://www.lopoly.me/#gallery" target="_blank" rel="noreferrer" className="nav-link w-nav-link">Gallery</a>
              <a href="https://www.lopoly.me/#team" target="_blank" rel="noreferrer" className="nav-link w-nav-link">Team</a>
              <a href="https://www.lopoly.me/#cta" target="_blank" rel="noreferrer" className="nav-link w-nav-link">Community</a>
              <a href="https://www.lopoly.me/#faq" target="_blank" rel="noreferrer" className="nav-link w-nav-link">FAQ</a>
              <a href="https://www.lopoly.me/contacts" target="_blank" rel="noreferrer" className="nav-link w-nav-link">Contacts</a>
            </nav>
            <div className="menu-button w-nav-button" style={{ "WebkitUserSelect": "text" }} aria-label="menu" role="button" tabIndex={0} aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false">
              <div className="w-icon-nav-menu"></div>
            </div></div>
          <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
        </div>
        {
          token ? (<AuthenticatedPane token={token} />) : (<AnonymousPane />)
        }
      </div>
    </ThemeProvider>
  );
}

export default App;
